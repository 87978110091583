import * as signalR from '@microsoft/signalr';
import { Component, HostListener, OnInit } from '@angular/core';
import { ConversationModel } from 'src/app/models/conversation';
import { MessageViewModel } from 'src/app/models/messageViewModel';
import $ from 'jquery';
import { ChatItem } from 'src/app/models/chatItem';
import { MessageTypes } from 'src/app/models/chatMessageTypes';
import { FormBuilder, Validators } from '@angular/forms';
import { UserData } from 'src/app/models/userData';


@Component({
  selector: 'app-servicedeskchat',
  templateUrl: './servicedesk-chat.component.html',
})
export class ServiceDeskChatComponent implements OnInit {
  miniform;
  chatHubConnection;
  conversation: ConversationModel = new ConversationModel();
  typeIndicatorIsShown: boolean = true;
  readMessage: boolean = false;
  quickReplies: string[] = [];
  chatForm = this.formBuilder.group({
    inputText: ['', Validators.required]
  });
  hideAgencyCode: boolean = false;
  userData: UserData = new UserData();
  delayMs: number = 500;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.setupConnection();
    this.chatForm.disable();
  }

  setupConnection(): void {
    this.userData.Domain = window.location.href;
    this.chatHubConnection = new signalR.HubConnectionBuilder()
      .withUrl('/servicechat')
      .build();
    this.chatHubConnection.on("ProcessMessage", (message: MessageViewModel) => this.ProcessMessageDelay(message));
    this.chatHubConnection.on("SetTypingIndicator", (value: boolean) => this.SetTypingIndicatorDelay(value));
    this.chatHubConnection.on("SetAllowUserInput", (value: boolean) => this.SetAllowUserInput(value));
    this.chatHubConnection.start()
      .then(() => this.chatHubConnection.invoke("StartChatConversation", this.userData.Domain))
      .catch(error => console.error("chathubconnection start error: " + error));
  }

  ProcessMessageDelay(message: MessageViewModel): void {
    const msg = message.ConversationMessages[0];
    if (msg != null && msg.Type != 0 && msg.Type != 1) {
      setTimeout(() => {
        this.ProcessMessage(message);
      }, this.delayMs);
    } else {
      this.ProcessMessage(message);
    }
  }

  SetTypingIndicatorDelay(value: boolean): void {
    if (value) {
      this.SetTypingIndicator(value);
    } else {
      setTimeout(() => {
        this.SetTypingIndicator(value);
      }, this.delayMs - 50);
    }
  }

  ProcessMessage(message: MessageViewModel): void {
    if (!message) return;
    if (message.allowUserInput === true) {
      this.chatForm.enable();
      $('.chat-input').focus();
    } else if (message.allowUserInput === false) {
      this.chatForm.disable();
    }

    if (message.customData !== null) {
      if (message.allowUserInput === false && message.QuickReplyOptions !== null) {
        this.quickReplies = message.QuickReplyOptions;
      } else {
        this.quickReplies = [];
      }
      if (message.customData.dataType === 'CollectINumber') {
        if (message.ConversationMessages[0].Message.includes('agency code')) {
          this.hideAgencyCode = false;
        } else {
          this.hideAgencyCode = true;
        }
      }
      this.miniform = message.customData.dataType;
    } else {
      this.miniform = null;
    }

    this.conversation.AddMessage(message);
    if (this.readMessage==false) {
      
      setTimeout(() => {
         this.GetIncidentBroadcast();
      }, 1000);
    }

    setTimeout(this.scrollDown, 100);
    
  }

  GetIncidentBroadcast() {
  
    this.chatHubConnection.invoke("IncidentBroadcast");
    this.readMessage = true;
  }


  SetTypingIndicator(value: boolean) {
    this.typeIndicatorIsShown = value;
  }

  SetAllowUserInput(value: boolean) {
    if (value) {
      this.chatForm.enable();
    }
  }

  CreateChatItem(message: string, type: MessageTypes): ChatItem {
    let chatItem = new ChatItem();
    chatItem.Message = message;
    chatItem.Type = type;
    chatItem.UserName = "ServiceChat User";
    return chatItem;
  }

  SendMessage(): void {
    let inputText = this.chatForm.value['inputText'];
    let chatItem = this.CreateChatItem(inputText, MessageTypes.UserText);
    this.chatHubConnection.invoke("SendMessage", chatItem);
    this.resetForm();
  }

  resetForm(): void {
    this.chatForm.reset();
    this.chatForm = this.formBuilder.group({
      inputText: ['', Validators.required]
    });
  }

  SendQuickReply(quickReply: string): void {
    let chatItem = this.CreateChatItem(quickReply, MessageTypes.UserText);
    this.chatHubConnection.invoke("SendMessage", chatItem);
  }

  SaveWelcomeReply(quickReply) {
    let nextEvent = "";
    if (quickReply == "Internal Network ID Password Reset") {
      nextEvent = "ADPasswordReset";
    } else if (quickReply == "Chat with a Technical Support Agent") {
      nextEvent = "ChatWithAgent";
    } else {
      nextEvent = "NPSPasswordReset";
    }

    this.userData.UserIntent = quickReply;
    let chatItem = this.CreateChatItem(quickReply, MessageTypes.UserText);
    this.chatHubConnection.invoke("SaveUserData", chatItem, this.userData, nextEvent);
  }

  SaveINumber(inumber) {
    this.userData.EmployeeNumber = inumber;
    let chatItem = this.CreateChatItem(inumber, MessageTypes.UserData);
    this.chatHubConnection.invoke("SaveUserData", chatItem, this.userData, "CollectAccountType");
  }

  SaveAccountType(quickReply) {
    this.userData.AccountType = quickReply;
    let chatItem = this.CreateChatItem(quickReply, MessageTypes.UserData);
    this.chatHubConnection.invoke("SaveUserData", chatItem, this.userData, "CollectName");
  }

  SaveName(name) {
    this.userData.UserName = name;
    let chatItem = this.CreateChatItem(name, MessageTypes.UserData);
    this.chatHubConnection.invoke("SaveUserData", chatItem, this.userData, "CollectBirthdate");
  }

  SaveBirthdate(birthdate) {
    this.userData.Birthdate = birthdate;
    let chatItem = this.CreateChatItem(birthdate, MessageTypes.UserData);
    this.chatHubConnection.invoke("SaveUserData", chatItem, this.userData, "CollectSocial");
  }

  SaveSocial(social) {
    this.userData.SocialLast4 = social;
    let chatItem = this.CreateChatItem("****", MessageTypes.UserData);
    this.chatHubConnection.invoke("SaveUserData", chatItem, this.userData, "TransferToOperator");
  }

  HandleTimeout(quickReply: string) {
    let chatItem = this.CreateChatItem(quickReply, MessageTypes.UserText);
    this.chatHubConnection.invoke("HandleTimeout", chatItem);
  }

  scrollDown() {
    var page = $("html, body");
    page.stop();
    page.animate({
      scrollTop: $(document).height()
    }, 500);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler($event: any) {
    const message = 'Window closing or redirecting, aborting conversation.';
    console.log(message);
    this.chatHubConnection.invoke("AbortConversation", message);
  }
}
