import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'welcome-form',
  template: `<button *ngFor="let quickReply of quickReplies" class="chat-item quick-reply" (click)="EmitSaveEvent(quickReply)">{{quickReply}}</button>`
})
export class WelcomeForm {
  @Input() quickReplies: string[] = [];
  @Output() save = new EventEmitter();

  constructor() { }

  EmitSaveEvent(quickReply) {
    this.save.emit(quickReply);
  }

}
