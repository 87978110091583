import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'timeout-form',
  template: `<button *ngFor="let quickReply of quickReplies" class="chat-item quick-reply" (click)="EmitSaveEvent(quickReply)">{{quickReply}}</button>`
})
export class TimeoutWarning {
  @Input() quickReplies: string[] = [];
  @Output() save = new EventEmitter();
  timer;

  constructor() { }

  ngOnInit() {
    this.timer = setTimeout(() => {
      this.EmitSaveEvent('no');
    }, 150000);
  }

  EmitSaveEvent(quickReply) {
    clearTimeout(this.timer);
    this.save.emit(quickReply);
  }

}
