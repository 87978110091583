<div class="chat-header">
  <div class="d-flex justify-content-between">
    <img class="chat-header-logo" src="../../../assets/NatGen_all_hor_white_rev.svg" alt="national general chatbot avatar logo">
  </div>
</div>

<div class="chat">
  <div class="container">

    <div *ngFor="let chat of conversation.ChatConversation" class="row{{chat.Type !== 0 && chat.Type !== 1 ? '' : ' flex-row-reverse'}}">
      <div class="col-10 mb-3{{chat.Type !== 0 && chat.Type !== 1 ? '' : ' text-end'}}">
        <div class="chat-item-container position-relative">
          <div *ngIf="chat.Type !== 0 && chat.Type !== 1" class="chat-icon"><img class="chat-icon-img" src="../../../assets/2021_1104_NGAAC_ChatBotIcon.svg" alt="national general chatbot avatar logo"></div>
          <div *ngIf="chat.Type !== 2" class="chat-item {{chat.Type !== 0 && chat.Type !== 1 ? 'bot-chat' : 'user-chat'}}">{{chat.Message}}</div>
          <div *ngIf="chat.Type === 2" class="chat-item {{chat.Type !== 0 && chat.Type !== 1 ? 'bot-chat' : 'user-chat'}}" [innerHTML]="chat.Message"></div>
        </div>
      </div>
    </div>

    <div *ngIf="typeIndicatorIsShown" class="row">
      <div class="col-10 mb-3">
        <div class="position-relative">
          <div class="chat-icon"><img class="chat-icon-img" src="../../../assets/2021_1104_NGAAC_ChatBotIcon.svg" alt="national general chatbot avatar logo"></div>
          <div class="type-indicator">
            <div class="ti-dot"></div>
            <div class="ti-dot"></div>
            <div class="ti-dot"></div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="miniform" [ngSwitch]="miniform" class="row">
        <welcome-form *ngSwitchCase="'IncidentBroadcast'" [quickReplies]="quickReplies" (save)="SaveWelcomeReply($event)" class="col-10 mb-3"></welcome-form>
        <inumber-form *ngSwitchCase="'CollectINumber'" [hideAgencyCode]="hideAgencyCode" (save)="SaveINumber($event)" class="col-10 mb-3"></inumber-form>
        <accounttype-form *ngSwitchCase="'CollectAccountType'" [quickReplies]="quickReplies" (save)="SaveAccountType($event)" class="col-10 mb-3"></accounttype-form>
        <birthdate-form *ngSwitchCase="'CollectBirthdate'" (save)="SaveBirthdate($event)" class="col-10 mb-3"></birthdate-form>
        <social-form *ngSwitchCase="'CollectSocial'" (save)="SaveSocial($event)" class="col-10 mb-3"></social-form>
        <name-form *ngSwitchCase="'CollectName'" (save)="SaveName($event)" class="col-10 mb-3"></name-form>
        <timeout-form *ngSwitchCase="'TimeoutWarning'" [quickReplies]="quickReplies" (save)="HandleTimeout($event)" class="col-10 mb-3"></timeout-form>
      </div>
    
    
  </div>
</div>

<div class="pb-3 chat-input-container{{chatForm.disabled ? ' chat-input-disabled' : ''}}">
  <div class="container">
    <form class="position-relative" [formGroup]="chatForm" (submit)="SendMessage()" ngNativeValidate>
      <input class="chat-input" type="text" formControlName="inputText" placeholder="Enter message" maxlength="256" required autofocus />
      <button class="btn chat-btn rounded-circle m-0 p-0" type="submit" [disabled]="chatForm.disabled">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-telegram" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
        </svg>
      </button>
    </form>
  </div>
</div>
