import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { ServiceDeskChatComponent } from './components/servicedesk-chat/servicedesk-chat.component';
import { INumberForm } from './forms/inumber';
import { BirthdateForm } from './forms/birthdate';
import { SocialForm } from './forms/social';
import { NameForm } from './forms/name';
import { WelcomeForm } from './forms/welcome';
import { AccountType } from './forms/accounttype';
import { TimeoutWarning } from './forms/timeout';

@NgModule({
  declarations: [
    AppComponent,
    ServiceDeskChatComponent,
    WelcomeForm,
    INumberForm,
    BirthdateForm,
    SocialForm,
    NameForm,
    AccountType,
    TimeoutWarning
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot([
      { path: 'natgenagency', component: ServiceDeskChatComponent },
      { path: 'itsm.ngic', component: ServiceDeskChatComponent },
      { path: '', component: ServiceDeskChatComponent, pathMatch: 'full' },
    ])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
