import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'birthdate-form',
  template: `
      <form class="birthdate-form chat-item bot-chat" [formGroup]="birthdateForm" (submit)="EmitSaveEvent()" ngNativeValidate>

      <div class="row">
        <div class="col">
          <label for="collect-birthmonth" class="form-label">Month:</label>
          <div class="input-group mb-3">
            <select id="collect-birthmonth" class="form-select" formControlName="month" required (change)="GetMonthDays($event)">
              <option value="">MM</option>
              <option value="01">01</option>
              <option value="02">02</option>
              <option value="03">03</option>
              <option value="04">04</option>
              <option value="05">05</option>
              <option value="06">06</option>
              <option value="07">07</option>
              <option value="08">08</option>
              <option value="09">09</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
          </div>
        </div>
        <div class="col">
           <label for="collect-birthday" class="form-label">Day:</label>
          <div class="input-group mb-1">
            <select id="collect-birthday" class="form-select" formControlName="day" required>
              <option value="">DD</option>
              <option *ngFor="let item of [].constructor(monthDays); let day = index" value="{{GetDayString(day)}}">{{GetDayString(day)}}</option>
            </select>
          </div>
        </div>
      </div>

        <button class="form-btn" type="submit">Submit</button>
      </form>`
})
export class BirthdateForm {
  @Output() save = new EventEmitter();
  monthDays = 31;
  birthdateForm = this.formBuilder.group({
    month: ['', Validators.required],
    day: ['', Validators.required]
  });

  constructor(private formBuilder: UntypedFormBuilder) { }

  EmitSaveEvent() {
    // const month = new Date(2012, this.birthdateForm.value['month'], 0).toLocaleString('default', { month: 'long' });
    const inputText = `${this.birthdateForm.value['month']}/${this.birthdateForm.value['day']}`;
    this.save.emit(inputText);
    this.birthdateForm.reset();
  }

  GetMonthDays(event) {
    if (event.target.value === '') {
      this.monthDays = 0;
    } else {
      this.monthDays = new Date(2012, event.target.value, 0).getDate();
    }
  }

  GetDayString(dayInt) {
    return `${dayInt + 1 < 10 ? '0' : ''}${dayInt + 1}`;
  }

}
