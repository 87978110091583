import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'name-form',
  template: `
    <div class="col-8 mb-3">
      <form class="chat-item bot-chat" [formGroup]="nameForm" (submit)="EmitSaveEvent()" ngNativeValidate>
        <label for="collect-name" class="form-label">First and Last Name:</label>
        <input id="collect-name" class="form-control" type="text" maxlength="255" formControlName="inputText" required>
        <button class="form-btn" type="submit">Submit</button>
      </form>
    <div>`
})
export class NameForm {
  @Output() save = new EventEmitter();
  nameForm = this.formBuilder.group({
    inputText: ['', Validators.required]
  });

  constructor(private formBuilder: UntypedFormBuilder) { }

  EmitSaveEvent() {
    const inputText = this.nameForm.value['inputText'];
    this.save.emit(inputText);
    this.nameForm.reset();
  }

}
