import { ChatItem } from "./chatItem";
import { MessageViewModel } from "./messageViewModel";

export class ConversationModel {
    ChatConversation: ChatItem[] = new Array<ChatItem>();

    constructor() {}

  AddMessage(message: MessageViewModel): void {
    if (message != null && message.ConversationMessages != null) {
      for (let internalMessage of message.ConversationMessages) {
        internalMessage.TimeSubmitted = new Date();
        this.ChatConversation.push(internalMessage);
      }
    }
  }
}
